<template>
    <div class=" rounded-lg p-4 box-shadow flex flex-col justify-between">
        <div class=" font-bold">
            <slot name="title">标题</slot>
        </div>
        <div class=" my-1">
            <slot>内容</slot>
        </div>
        <div>
            <span v-for="t in tags">{{ t }}</span>
        </div>
        <div>
            <slot name="footer"></slot>
        </div>
    </div>
</template>
<script setup lang="ts">
    const props = defineProps<{
        tag?: string | string[]
    }>()

    const tags = computed(() => {
        if (typeof props.tag === "string") {
            return props.tag.split(",")
        }
        return props.tag
    })
</script>
<style lang="scss" scoped>
    .box-shadow {
        box-shadow: 2px 0 10px 2px rgba(113, 113, 246, 0.11);
    }
</style>