<template>
    <nav
        class="sticky top-0 z-40 w-full backdrop-blur flex-none transition-colors duration-500 lg:z-50 lg:border-b lg:border-slate-900/10 dark:border-slate-50/[0.06] bg-white supports-backdrop-blur:bg-white/95 dark:bg-slate-900/75">
        <div class=" max-w-7xl mx-auto py-2 flex justify-between items-center">
            <div>
                <div class=" font-bold text-xl inline-flex items-center justify-center gap-4 bg-white">
                    <a href="/">
                        <img src="/avatar.jpg" class=" w-10 h-10 rounded-full" alt="一个橙子" />
                    </a>
                    <a href="/" class="bg-[linear-gradient(120deg,#9a5416,#ffaa3f)] bg-clip-text text-transparent">
                        一个橙子
                    </a>
                </div>
            </div>
            <div class=" flex justify-around gap-4">
                <ClientOnly>
                    <template v-if="loginStatus != '1'">
                        <a :href="`/user/login?redirect_url=${href}`" class=" hover:text-blue-500">
                            <button class=" hover:underline">去登录</button>
                        </a>
                        <a href="/user/register" class=" hover:text-blue-500">
                            <button class=" hover:underline">去注册？</button>
                        </a>
                    </template>
                    <template v-else>
                        <a href="/user/center" class=" hover:text-blue-500">
                            <button class=" hover:underline">用户中心</button>
                        </a>
                    </template>
                </ClientOnly>
            </div>
        </div>
    </nav>
    <div class=" xl:max-w-[1380px] w-full m-auto overflow-auto">
        <main class="px-2 no-scrollbar min-h-[calc(100vh-120px)]">
            <slot></slot>
        </main>
        <footer>
            <div class="  w-full p-2 rounded flex justify-center">
                <div class=" flex text-sm">
                    <div>
                        <img src="/gongan.png" class="w-[16px] float-left mr-1" alt="" srcset="">
                        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=61012602000058" rel="noreferrer"
                            title="陕公网安备61012602000058号" target="_blank">陕公网安备61012602000058号</a>
                    </div>
                    <a class=" ml-4" href="https://beian.miit.gov.cn/" title="陕ICP备2023011778号">陕ICP备2023011778号</a>
                </div>
            </div>
        </footer>
    </div>


    <ClientOnly>
        <div class="fixed inset-0 z-50" v-if="isProd && isLeaf"></div>
    </ClientOnly>
</template>
<script setup lang="ts">
    import { usePageLeave } from "@vueuse/core"
    const isProd = useRuntimeConfig().public.isProd

    let href = ref()
    let loginStatus = ref() // 1 登录 0 未登录
    if (import.meta.client) {
        const token = sessionStorage.getItem("auth") || ""
        loginStatus.value = !isTokenExpired(token) ? "1" : "0"
        href.value = location.href
    }

    // 监测页面离开
    const isLeaf = usePageLeave()

    onMounted(() => {
        if (isProd) {
            try {
                setInterval(() => { Function("debugger")() }, 50)
            } catch (error) {

            }
        }
    })
</script>
<style lang="less" scoped>
    .index-layout {
        // background: #f6f6f6;

        &__title {
            height: 64px;
            color: #222226;
            background: #fff;
            box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 20px;

            position: sticky;
            top: 0px;
            z-index: 1;

            .extra {
                // width: 120px;
                margin-right: 20px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                > ::slotted(span) {
                    margin-right: 10px;
                }

                .user-info {
                    cursor: pointer;
                }

                a {
                    text-decoration: none;
                    color: #000;
                }
            }
        }

        &__content {
            max-width: 900px;
            margin: 0 auto;
            margin-top: 10px;
        }
    }

    .header-search {
        :deep(.el-input.is-disabled .el-input__inner) {
            cursor: pointer !important;
        }
    }
</style>