<template>
    <CzCard v-if="hasContent" class=" mt-4">
        <template #title>
            <span class=" text-green-900"></span>
        </template>
        <div v-html="conetent">
        </div>
    </CzCard>
</template>
<script setup lang="ts">

    const { data: notification } = await useFetch<{ data: any }>("/blog/notification/first", { parseResponse: JSON.parse })
    const conetent = (unref(notification)?.data?.content || "")
    // 是否有实际内容
    const hasContent = computed(() => {
        return conetent.replace(/<.*?>/g, '').length > 0
    })
</script>