<template>
  <Title>{{ `一个橙子,软件开发,软件制作,软件运维,软件推荐|www.yigechengzi.com` }}</Title>
  <Meta name="keywords" content="前端,Vue,React,Angular,Nuxt,Howtools,Howuse,营销,SCADA">
  </Meta>
  <Meta name="description" content="一个橙子pro，一个资深的前端开发工程师，前端库howtools、howuse的作者">
  </Meta>

  <NuxtLayout>
    <Notification />
    <div class=" grid grid-cols-3 max-lg:grid-cols-1 gap-3 mt-4">
      <template v-for="r in productRecords">
        <CzCard>
          <template #title>
            <span class=" text-[#563BDA]">{{ r.name }}</span>
          </template>
          <div class=" py-4">
            {{ r.description }}
          </div>
          <template #footer>
            <a v-if="r.docLink" :href="r.docLink" target="_blank"
              class=" cursor-pointer float-left hover:underline hover:text-blue-500">
              <span>操作文档</span>
            </a>
            <a :href="r.link" target="_blank" class=" cursor-pointer float-right hover:underline  hover:text-blue-500">
              <span>去使用</span>
            </a>
          </template>
        </CzCard>
      </template>
    </div>
    <!-- 文章列表 -->
    <CzCard class=" mt-4">
      <template #title>
        <span>历史文章列表</span>
      </template>
      <div v-for="(article, index) in records" class=" text-black dark:text-white py-2 hover:bg-[#FAFAFA]">
        <a :href="`/article_detail/${article.id}.html`">
          <h2 class=" font-bold hover:underline text-[#060E4B]">{{ article.title }}</h2>
          <p class=" text-sm text-gray-500 my-2">{{ article.summary }}</p>
          <div class=" text-gray-400 flex gap-x-8 flex-wrap">
            <div class=" inline-flex gap-2 flex-wrap ">
              <span v-for="tag in tags(article.tag)" class="bg-[#f2f3f5] px-1 rounded flex items-center text-sm">{{
                tag
                }}</span>
              <span>更新时间：{{ article.updatedAt }}</span>
            </div>
          </div>
        </a>
      </div>
      <div class=" text-center py-2 text-xl hover:text-blue-500 hover:underline">
        <a href="/article/2.html">查看更多</a>
      </div>
    </CzCard>
  </NuxtLayout>
</template>

<script lang="ts" setup>
  const tags = (tag: string) => tag ? tag.split(",") : []

  const { data, status } = await useAsyncData<{ data: any }>("blog-list", async () => {
    const result = await $fetch<{ data: any }>(`/blog/article/list`, {
      parseResponse: JSON.parse,
    })

    return result
  })

  const { data: productData } = await useAsyncData<{ data: any }>("product-list", async () => {
    const result = await $fetch<{ data: any }>(`/blog/product/list`, {
      parseResponse: JSON.parse,
    })

    return result
  })

  const records = computed(() => {
    return unref(data)?.data?.records
  })

  const productRecords = computed(() => {
    return unref(productData)?.data || []
  })

</script>
<style scoped lang="less">
  .user-info {
    cursor: pointer;
    margin-right: 16px;
  }

  @keyframes ArticleblowUp {
    0% {
      transform: scale(1) translateX(0);
    }

    100% {
      transform: scale(1.01) translateX(5px);
    }
  }

  .index-footer {
    text-align: center;
    background-color: #000;
    color: #FFF;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;

    a {
      text-decoration: none;

      &:link {
        text-decoration: none;
        color: #FFF;
      }

      &:visited {
        text-decoration: none;
        color: #FFF;
      }

      &:hover {
        text-decoration: none;
        color: #FFF;
      }

      &:active {
        text-decoration: none;
        color: #FFF;
      }
    }
  }
</style>
