const text = '这是一段很长的文本';
const totalTextLen = text.length;
const formatStr = () => {
    const ele = document.getElementsByClassName('demo')[0];
    const lineNum = 2;
    const baseWidth = window.getComputedStyle(ele).width;
    const baseFontSize = window.getComputedStyle(ele).fontSize;
    const lineWidth = +baseWidth.slice(0, -2);

    // 所计算的strNum为元素内部一行可容纳的字数(不区分中英文)
    const strNum = Math.floor(lineWidth / +baseFontSize.slice(0, -2));

    let content = '';

    // 多行可容纳总字数
    const totalStrNum = Math.floor(strNum * lineNum);

    const lastIndex = totalStrNum - totalTextLen;

    if (totalTextLen > totalStrNum) {
        content = text.slice(0, lastIndex - 3).concat('...');
    } else {
        content = text;
    }
    ele.innerHTML = content;
}

export function isTokenExpired(token: string) {
    // 分割JWT的三部分
    const parts = token.split('.');
    if (parts.length !== 3) {
        return false;
    }

    // 解码负载部分（第二部分）
    const payload = JSON.parse(atob(parts[1]));

    // 检查是否存在过期时间声明
    if (!payload.exp) {
        return false;
    }

    // 将过期时间转换为时间戳
    const expirationTime = payload.exp * 1000; // 转换为毫秒

    // 获取当前时间戳
    const currentTime = Date.now();

    // 比较当前时间和过期时间
    return currentTime > expirationTime;
}