import { default as abouth5xSgjeYGOMeta } from "/workspace/ygcz/ygcz-portal/pages/about.vue?macro=true";
import { default as _91id_93_46htmlZAcOmXg8sVMeta } from "/workspace/ygcz/ygcz-portal/pages/article_detail/[id].html.vue?macro=true";
import { default as _91current_93_46html8YSaKwzgJhMeta } from "/workspace/ygcz/ygcz-portal/pages/article/[current].html.vue?macro=true";
import { default as indexGWo8YKEUewMeta } from "/workspace/ygcz/ygcz-portal/pages/book/index.vue?macro=true";
import { default as _91id_93_46htmlo67kd7LpOfMeta } from "/workspace/ygcz/ygcz-portal/pages/column/[id].html.vue?macro=true";
import { default as indexKidJ3EMnPbMeta } from "/workspace/ygcz/ygcz-portal/pages/column/index.vue?macro=true";
import { default as _91id_93_46htmlQVjjUnXKTzMeta } from "/workspace/ygcz/ygcz-portal/pages/files/[id].html.vue?macro=true";
import { default as indexcZRiRrviA0Meta } from "/workspace/ygcz/ygcz-portal/pages/files/index.vue?macro=true";
import { default as indexnxuhZyh0TIMeta } from "/workspace/ygcz/ygcz-portal/pages/index/index.vue?macro=true";
import { default as centersnurJISqu8Meta } from "/workspace/ygcz/ygcz-portal/pages/user/center.vue?macro=true";
import { default as friendsjCkA8LoYs1Meta } from "/workspace/ygcz/ygcz-portal/pages/user/friends.vue?macro=true";
import { default as Login8H2pswXKC6Meta } from "/workspace/ygcz/ygcz-portal/pages/user/Login.vue?macro=true";
import { default as payJQiqsoUuxKMeta } from "/workspace/ygcz/ygcz-portal/pages/user/pay.vue?macro=true";
import { default as RegisterYNB9HJWq2rMeta } from "/workspace/ygcz/ygcz-portal/pages/user/Register.vue?macro=true";
import { default as vipSEOWLPEe19Meta } from "/workspace/ygcz/ygcz-portal/pages/user/vip.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/about.vue")
  },
  {
    name: "article_detail-id.html",
    path: "/article_detail/:id().html",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/article_detail/[id].html.vue")
  },
  {
    name: "article-current.html",
    path: "/article/:current().html",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/article/[current].html.vue")
  },
  {
    name: "book",
    path: "/book",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/book/index.vue")
  },
  {
    name: "column-id.html",
    path: "/column/:id().html",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/column/[id].html.vue")
  },
  {
    name: "column",
    path: "/column",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/column/index.vue")
  },
  {
    name: "files-id.html",
    path: "/files/:id().html",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/files/[id].html.vue")
  },
  {
    name: "files",
    path: "/files",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/files/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/index/index.vue")
  },
  {
    name: "user-center",
    path: "/user/center",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/user/center.vue")
  },
  {
    name: "user-friends",
    path: "/user/friends",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/user/friends.vue")
  },
  {
    name: "user-Login",
    path: "/user/Login",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/user/Login.vue")
  },
  {
    name: "user-pay",
    path: "/user/pay",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/user/pay.vue")
  },
  {
    name: "user-Register",
    path: "/user/Register",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/user/Register.vue")
  },
  {
    name: "user-vip",
    path: "/user/vip",
    component: () => import("/workspace/ygcz/ygcz-portal/pages/user/vip.vue")
  }
]